import React from "react";
import { Button, ButtonGroup, Container } from 'react-bootstrap';

function PledgeOptions({ selectedCategory, setSelectedCategory, categories }) {
  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
  };

  return (
    <>
        <Container className="pledge-buttons-container m-3 text-center">
        <ButtonGroup>
          {categories.sort((a, b) => a.amount - b.amount).map((category, index) => (
            <Button
              key={category.amount}
              variant={selectedCategory === index ? "secondary" : "outline-secondary"}
              onClick={() => handleCategoryClick(index)}
              className="m-1"
            >
              {category.name}
            </Button>
          ))}
        </ButtonGroup>
      </Container>


    </>
  );
}

export default PledgeOptions;