import React, { Component } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import { addPledge, addNamedPledge } from "./pledge_service";
import MissingInputForm from "./MissingInputForm";
import PledgeHeader from "./PledgeHeader";


class CustomPledges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      isLoading: false,
      identifier: "",
      message: "",
      needMoreInfo: false,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      tableNumber: ""
    };
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);

    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let message = "";
    let needMoreInfo = false;

    this.props.setIsLoading(true);

    const result = await addPledge(this.state.dollarAmount, this.state.identifier);

    if (result && result.success === "true") {
      message = <span className="message success">Successfully made a pledge for {result.attendee} for ${this.state.dollarAmount}! Proceed to process the next pledger.</span>;
    } else {
      message = <span className="message info">We need some more information to complete the pledge.</span>;
      needMoreInfo = true;
    }

    if (needMoreInfo) {
      this.setState({ message: message, needMoreInfo: needMoreInfo, identifier: "" });
    } else {
      this.setState({ message: message, needMoreInfo: needMoreInfo, identifier: "", dollarAmount: "" });
    }
    
    this.props.setIsLoading(false);
  };

  handleSubmitClick = async (e) => {
    e.preventDefault();
    let message = "";

    this.props.setIsLoading(true);

    await addNamedPledge(this.state.dollarAmount, this.state.firstname, this.state.lastname, this.state.phoneNumber, this.state.tableNumber);

    message = <span className="message success">Successfully made a pledge for {this.state.firstname} {this.state.lastname} for ${this.state.dollarAmount}! Proceed to process the next pledger.</span>;

    this.setState({ message: message, needMoreInfo: false, dollarAmount: "" });
    this.props.setIsLoading(false);
  };

  handleInputChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };


  render() {
    return (
      <>
               <h1>
              <span>Enter Custom FAN</span>{this.props.titlePrefix}
            </h1>
            <p  className="message-well">{this.state.message}</p>

            <PledgeHeader
              selectedPledge={this.props.selectedPledge}
              setSelectedPledge={this.props.setSelectedPledge}
              pledgeButtons={this.props.pledgeButtons}
            />

            <div className="auth-wrap">
              <div className="auth-col pledges">
                {this.state.needMoreInfo ? <>
                  <MissingInputForm 
                  firstname={this.state.firstname}
                  lastname={this.state.lastname}
                  phoneNumber={this.state.phoneNumber}
                  tableNumber={this.state.tableNumber}
                  handleSubmitClick={this.handleSubmitClick}
                  handleInputChange={this.handleInputChange}
                  />
                
                </> : <>
                
                <form className="form-validate" onSubmit={this.handleSubmit}>
                  <p className="contactform-field contactform-textarea">
                    <label className="contactform-label">
                    {this.props.selectedType === 0 ? "Barcode": "Paddle Number"}
                    </label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="identifier"
                        data-required="text"
                        value={this.state.identifier}
                        onChange={(e) =>
                          this.setState({ identifier: e.target.value })
                        }
                        placeholder={this.props.selectedType === 0 ? "Barcode": "Paddle Number"}
                      />
                    </span>
                  </p>

                  <p className="contactform-field contactform-text">
                      <label className="contactform-label">Amount</label>
                      <span className="contactform-input">
                        <input
                      className="custom-input"
                          type="number"
                          value={this.state.dollarAmount}
                          onChange={(e) =>
                            this.setState({ dollarAmount: e.target.value })
                          }
                          required
                        />
                      </span>
                    </p>

                    <p className="auth-submit">
                    <input type="submit" 
                    className="custom-pledge-button"
                    value="Add Pledge" />
                  </p>
                </form>
                </>}

                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
      </>
    );
  }
}

export default CustomPledges;