import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "../css/Pledges.css"; // Create a separate CSS file for styling

import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import AdditionalNotes from './AdditionalNotes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PledgeList extends Component {
  constructor(props) {
    super(props);

    const isTest = window.location.hostname.includes('test.');

    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      isLoading: false,
      categories: [],
      expandedRowID: null,
      expandedRowType: null,
      notesText: "",
      receiptInfo: null,
      category: "all",
      titlePrefix: isTest? <> [Staff Only, Test]</>: <> [Staff Only]</>
    };

    this.intervalId = null;
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);

    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  fetchCategories = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/brenda/pledgeCategories`)
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            this.setState({ categories: myDataList });
          }
        }
      });
  };

  fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/pledges`)
      .then((response) => response.json())
      .then((dataList) => {

        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];
            myDataList.sort((a, b) => b.Amount - a.Amount);

            this.setState({ searchResults: myDataList });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({isLoading:true}, ()=> {
      this.fetchCategories();
      this.fetchData();
      this.setState({isLoading: false});

      this.intervalId = setInterval(this.fetchData, 5000);
    })

  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


  handleDonationChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ dollarAmount: selectedValue, donationType: selectedValue });


    if (selectedValue === "custom") {
      this.setState({ customAmount: "" });
    }
  };

  getIds = () => {
    const idList = this.state.ids
      .split("\n")
      .map((id) => id.trim())
      .filter((id) => id !== "")
      .reverse();

    return idList;
  };

  addPledge = async (id_list) => {
    let amount = 0.0;

    if (id_list.length > 0) {
      if (this.state.donationType === "custom") {
        amount = this.state.customAmount;
      } else {
        amount = this.state.dollarAmount;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/pledges`, {
          method: "POST",
          body: JSON.stringify({
            Identifiers: id_list,
            Amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return true;
      } catch (err) {
        throw new Error("Try submitting pledges again");
      }
    } 
    
    throw new Error("Missing either Paddle Numbers or QR Codes");

  };

  handleDelete = (event, pledgeId) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/pledges?pledgeId=${pledgeId}`, {
      method: "DELETE", 
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        if (response.ok) {
          const mySearchResults = [...this.state.searchResults];
          const updatedItems = mySearchResults.filter(
            (item) => item.ID !== pledgeId
          );
          this.setState({ searchResults: updatedItems });
        } else {
          console.error("Failed to delete item.");
        }
      })
      .catch((error) => {
        console.error("Error while deleting item:", error);
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {
      const id_list = this.getIds();
  
      if (id_list.length === 0) {
        this.setState({ error: "Please enter a paddle number or a QR code", isLoading: false });
      } else if (this.state.dollarAmount === "") {
        this.setState({ error: "Please select a pledge amount", isLoading: false });
      } else {
        try {
          const result = await this.addPledge(id_list);
          if (result) {
            this.setState({
              ids: "",
              dollarAmount: "",
              donationType: "",
              isLoading: false,
              error: ""
            }, () => {
              this.fetchData();
            });
          }
        } catch (error) {
          console.error("Error adding result:", error);
          this.setState({ isLoading: false, error: "Something went wrong, try submitting again!" });
        }
      }
    });
  };
  

  convertToPST = (timestampString) => {
    const date = new Date(timestampString);
  
    if (isNaN(date.getTime())) {
      return "Invalid Time";
    }
  
    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;
  
    const pstTime = new Date(date - offsetMilliseconds);
    
    const pstTimeString = pstTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
  
    return pstTimeString;
  };
  

  handlePaid = async (event, bidID) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/pledges`;

    const requestBody = {
      ID: bidID
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Bid paid successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error paying bid", error);
    }
};


updateNotes = async (value) => {
  const stringValue = String(value);
  this.setState({ notesText: stringValue });
};

addNote = async (e, pledge) => {
  e.preventDefault();
  this.setState({ isLoading: true });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/add-pledge-note`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: pledge.ID, CreateDate: pledge.CreateDate, Note: this.state.notesText }),
      }
    );

    if (response.ok) {
      toast.success(`Note added for pledge.`);
      this.fetchData();
    } else {
      throw new Error("Failed to add note.");
    }

    this.setState({ isLoading: false, expandedRowID: null, notesText: "" });

  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

updateExpandRow = (e, gift_ID, type) => {
  e.preventDefault();

  console.log("SCREAM");
  console.log(gift_ID);

  if (this.state.expandedRowID === gift_ID) {
    this.setState({
      expandedRowID: null
    });

    return;
  }

  // Find the item with matching receiptID
  const foundItem = this.state.searchResults.find(
    (item) => item.ID === gift_ID
  );

  console.log(foundItem);

  if (foundItem) {
    // Update receiptInfo with the found item
    this.setState({
      expandedRowID: gift_ID,
      receiptInfo: foundItem,
      expandedRowType: type,
    });
  }
};

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Pledges</span>{this.state.titlePrefix}
            </h1>
            {this.state.searchResults.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Name</td>
                      <td className="cart-quantity">Amount</td>
                      <td className="cart-quantity">Amount Paid</td>
                      <td className="cart-price">Payment Link</td>
                      <td className="cart-price">Notes</td>

                      <td className="cart-del">&nbsp;</td>
                    <td className="cart-del">&nbsp;</td>
                    <td className="cart-del">&nbsp;</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchResults
                      .slice()
                      .sort(
                        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
                      ) // Sort by the 'Added' field in descending order
                      .map((result, index) => (
                        <React.Fragment key={index}>
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.Name}</td>
                          <td className="cart-quantity">
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result.amount)}<br/>
                            <span style={{ color: result["isPaid"] ? '#0ba011' : '#e82131' }}>
                              {result["isPaid"] ? '[Paid]' : '[Not Paid]'}
                            </span>
                          </td>

                          <td className="cart-quantity">
                            {result.amountPaid > 0 && <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result.amountPaid)}</span>}<br/>
                          </td>

                          <td className="cart-ttl">
                            <a style={{color: "#2dccd3"}} href={result["QR Code"] ? `https://donate.bigsisters.bc.ca/grapejuicefan?pledgeAmount=${result.amount}&code=${result["QR Code"]}` : `https://donate.bigsisters.bc.ca/grapejuicefan?pledgeAmount=${result.amount}`} target="_blank" rel="noopener noreferrer">LINK</a></td>

                          <td className="cart-ttl" dangerouslySetInnerHTML={{ __html: result.notes }}></td>

                          <td className="cart-del">
                            <a
                              className="cart-paid"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handlePaid(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-remove"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>



                          <td className="cart-del">
                            <a
                              className="cart-notes"
                              href="/"
                              onClick={(e) => {
                                this.updateExpandRow(
                                  e,
                                  result.ID,
                                  "Note"
                                );
                              }}
                              role="button"
                            >
                              {"\u00A0"}
                            </a>
                          </td>   

                        </tr>

                      {this.state.expandedRowID === result.ID && (
                        <tr className="notes-row">
                          <td colSpan="9">
                            <>
                              <AdditionalNotes
                                text={this.state.notesText}
                                handleChange={this.updateNotes}
                                addNote={this.addNote}
                                batch={result}
                              />
                            </>

                          </td>
                        </tr>
                      )}
</React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
          <ToastContainer />
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default PledgeList;