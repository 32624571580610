import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Container } from "react-bootstrap";

function MissingInputForm({
  firstname,
  lastname,
  phoneNumber,
  tableNumber,
  paddleNumber,
  handleSubmitClick,
  handleInputChange,
}) {
  return (
    <>
      <form className="form-validate">
        <p className="contactform-field contactform-textarea">
          <label className="contactform-label">First Name</label>
          <span className="contactform-input">
            <input
              className="pledge-input"
              type="text"
              name="firstname"
              data-required="text"
              value={firstname}
              onChange={(e) => handleInputChange("firstname", e.target.value)}
            />
          </span>
        </p>

        <p className="contactform-field contactform-textarea">
          <label className="contactform-label">Last Name</label>
          <span className="contactform-input">
            <input
              className="pledge-input"
              type="text"
              name="lastname"
              data-required="text"
              value={lastname}
              onChange={(e) => handleInputChange("lastname", e.target.value)}
            />
          </span>
        </p>

        <p className="contactform-field contactform-textarea">
          <label className="contactform-label">Phone Number</label>
          <span className="contactform-input">
            <input
              className="pledge-input"
              type="text"
              name="phoneNumber"
              data-required="text"
              value={phoneNumber}
              onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
            />
          </span>
        </p>

        <p className="contactform-field contactform-textarea">
          <label className="contactform-label">Table Number</label>
          <span className="contactform-input">
            <input
              className="pledge-input"
              type="text"
              name="tableNumber"
              data-required="text"
              value={tableNumber}
              onChange={(e) => handleInputChange("tableNumber", e.target.value)}
            />
          </span>
        </p>

        <p className="contactform-field contactform-textarea">
          <label className="contactform-label">Paddle Number</label>
          <span className="contactform-input">
            <input
              className="pledge-input"
              type="text"
              name="paddleNumber"
              data-required="text"
              value={paddleNumber}
              onChange={(e) => handleInputChange("paddleNumber", e.target.value)}
            />
          </span>
        </p>

        <p className="contactform-field contactform-text">
          <label className="contactform-label"></label>
          <span className="contactform-input">
            <button
              type="button"
              className="custom-pledge-button"
              onClick={(e) => handleSubmitClick(e)}
            >
              Submit
            </button>
          </span>
        </p>
      </form>
    </>
  );
}

export default MissingInputForm;