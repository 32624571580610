export const addPledge = async (amount, identifier) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/single-pledge`, {
          method: "POST",
          body: JSON.stringify({
            Identifier: identifier,
            Amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          return false;
        }

        const responseBody = response.json();

        return responseBody;
      } catch (err) {
        return false;
      }
}


export const addNamedPledge = async (amount, firstname, lastname, phoneNumber, tableNumber, paddleNumber) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/single-pledge/named`, {
          method: "POST",
          body: JSON.stringify({
            Firstname: firstname,
            Lastname: lastname,
            Amount: amount,
            PhoneNumber: phoneNumber,
            TableNumber: tableNumber,
            PaddleNumber: paddleNumber
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          return false;
        }

        const responseBody = response.json();

        return responseBody;
      } catch (err) {
        return false;
      }
}


