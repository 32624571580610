import React, { Component } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import { addPledge, addNamedPledge } from "./pledge_service";
import MissingInputForm from "./MissingInputForm";
import PledgeOptions from "./PledgeOptions";
import PledgeHeader from "./PledgeHeader";


class Pledges extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      identifier: "",
      message: props.menuItems[props.selectedType].welcome,
      needMoreInfo: false,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      tableNumber: "",
      paddleNumber: "",
      selectedCategory: 0,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the selectedType prop has changed
    if (prevProps.selectedType !== this.props.selectedType) {
      this.setState({
        message: this.props.menuItems[this.props.selectedType].welcome,
      });
    }
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);

    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const amount = this.props.categories[this.state.selectedCategory].amount;

    this.props.setIsLoading(true);
    let message = "";
    let needMoreInfo = false;

    this.setState({ dollarAmount: amount });

    const result = await addPledge(amount, this.state.identifier);

    if (result && result.success === "true") {
      message = (
        <span className="message success">
          Successfully made a pledge for {result.attendee} for $
          {this.state.dollarAmount}! Proceed to process the next pledger.
        </span>
      );
    } else {
      message = (
        <span className="message info">
          We need some more information to complete the pledge.
        </span>
      );
      needMoreInfo = true;
    }

    this.setState({
      message: message,
      needMoreInfo: needMoreInfo,
      identifier: "",
    });

    this.props.setIsLoading(false);
  };

  handleSubmitClick = async (e) => {
    e.preventDefault();
    let message = "";

    this.props.setIsLoading(true);

    await addNamedPledge(
      this.state.dollarAmount,
      this.state.firstname,
      this.state.lastname,
      this.state.phoneNumber,
      this.state.tableNumber,
      this.state.paddleNumber
    );

    message = (
      <span className="message success">
        Successfully made a pledge for {this.state.firstname}{" "}
        {this.state.lastname} for ${this.state.dollarAmount}! Proceed to process
        the next pledger.
      </span>
    );

    this.setState({ message: message, needMoreInfo: false });
    this.props.setIsLoading(false);
  };

  handleInputChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  setSelectedCategory = (selectedCategory) => {
    this.setState({
      selectedCategory,
    });
  };

  render() {
    return (
      <>
         <h1>
              <span>Enter FAN</span>{this.props.titlePrefix}
            </h1>
        <p className="message-well">{this.state.message}</p>



        <div className="auth-wrap">
          <div className="auth-col pledges">
          <PledgeHeader
          selectedPledge={this.props.selectedPledge}
          setSelectedPledge={this.props.setSelectedPledge}
          pledgeButtons={this.props.pledgeButtons}
        />

            {this.state.needMoreInfo ? (
              <>
                <MissingInputForm
                  firstname={this.state.firstname}
                  lastname={this.state.lastname}
                  phoneNumber={this.state.phoneNumber}
                  tableNumber={this.state.tableNumber}
                  paddleNumber={this.state.paddleNumber}
                  handleSubmitClick={this.handleSubmitClick}
                  handleInputChange={this.handleInputChange}
                />
              </>
            ) : (
              <>
                <form className="form-validate" onSubmit={this.handleSubmit}>
                    <PledgeOptions
                      selectedCategory={this.state.selectedCategory}
                      setSelectedCategory={this.setSelectedCategory}
                      categories={this.props.categories}
                    />
                  <p className="contactform-field contactform-textarea">
                    <label className="contactform-label">
                      {this.props.selectedType === 0 ? "Barcode": "Paddle Number"}
                    </label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="identifier"
                        data-required="text"
                        value={this.state.identifier}
                        onChange={(e) =>
                          this.setState({ identifier: e.target.value })
                        }
                      />
                    </span>
                  </p>

                  {this.props.selectedType === 1 && <>
                    <p className="auth-submit">
                    <input type="submit" 
                    className="custom-pledge-button"
                    value="Submit" />
                  </p>
                  </>}
                </form>
              </>
            )}

            {this.state.error && (
              <div className="err404">
                <p className="err404-search">{this.state.error}</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Pledges;