import React from "react";
import { Button, ButtonGroup, Container } from 'react-bootstrap';

function PledgeHeader({ selectedPledge, setSelectedPledge, pledgeButtons }) {
  const handlePledgeClick = (index) => {
    setSelectedPledge(index);
  };

  return (
    <>
        <Container className="pledge-buttons-container m-3 text-center">
        <ButtonGroup>
          {pledgeButtons.map((button, index) => (
            <Button
              key={index}
              variant={selectedPledge === index ? "primary" : "outline-primary"}
              onClick={() => handlePledgeClick(index)}
              className="m-1"
            >
              {button.label}
            </Button>
          ))}
        </ButtonGroup>
      </Container>

    </>
  );
}

export default PledgeHeader;